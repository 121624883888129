import { ChangeEvent, useState, MouseEvent } from "react";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styles from "./WithdrawReportUploader.module.scss";
import {
  FulfillmentType,
  MarketplaceType,
  ServerErrors,
} from "src/models/Models";
import { parseReport } from "src/services/WithdrawFromCirculationApiService";
import { toast } from "react-toastify";
import axios from "axios";
import { ParseWithdrawFromCirculationFileResponse } from "src/models/WithdrawFromCirculationModels";
import { Loader } from "src/components/Loader/Loader";

interface TProps {
  onChange: (file: File, hasRecords: boolean) => void;
  trueApiToken?: string;
  token: string;
  sellerInn: string;
  disabled?: boolean;
  marketplaceType: MarketplaceType;
  extension: string;
}

export default function WithdrawReport({
  onChange,
  trueApiToken,
  sellerInn,
  token,
  disabled,
  marketplaceType,
  extension,
}: TProps) {
  const [fileName, setFileName] = useState<string | null>(null);
  const [parsedDetails, setParsedDetails] =
    useState<ParseWithdrawFromCirculationFileResponse | null>(null);

  const [isParsing, setIsParsing] = useState(false);

  const handleFileOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (!file || !trueApiToken) return;

    setFileName(file.name);

    try {
      setIsParsing(true);

      let response = await parseReport(
        file,
        trueApiToken,
        token,
        sellerInn,
        marketplaceType
      );
      setParsedDetails(response);

      onChange(file, response.totalUniqueProducts > 0);
    } catch (error) {
      if (axios.isAxiosError<ServerErrors>(error)) {
        toast.error(`${error.response?.data.errors.join("; ")}`);
      } else {
        console.error(error);
      }
      setParsedDetails(null);
    } finally {
      setIsParsing(false);
    }
  };

  const clearHtmlInput = async (event: MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  return (
    <div>
      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        fullWidth
        className={styles.fileButton}
        disabled={disabled}
      >
        {fileName?.substring(0, 35) || "Выберите файл"}
        <input
          className={styles.hiddenInput}
          type="file"
          onChange={handleFileOnChange}
          onClick={clearHtmlInput}
          accept={extension}
        />
      </Button>

      <Loader show={isParsing} message="Проверяем статус каждого КИЗ" />

      <div>
        {parsedDetails && !isParsing && (
          <div className={styles.parsedDetails}>
            <div>Количество строк в отчёте: {parsedDetails.totalProducts}</div>
            <div>
              Уникальных КИЗов для сверки: {parsedDetails.totalUniqueProducts}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
