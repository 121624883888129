import { ParseUpdFileResponse } from "src/models/UpdModels";
import styles from "./UpdDetails.module.scss";
import Tooltip from "src/components/Tooltip/Tooltip";
import { Loader } from "src/components/Loader/Loader";

interface TProps {
  parsedDetails: ParseUpdFileResponse | null;
  isParsing: boolean;
}

export default function UpdDetails({ parsedDetails, isParsing }: TProps) {
  return (
    <>
      <Loader show={isParsing} message="Проверяем статус каждого КИЗ" />
      <div>
        {parsedDetails && !isParsing && (
          <div className={styles.parsedDetails}>
            <div>Общее количество товаров: {parsedDetails.totalProducts}</div>
            <div>
              Количество маркированных товаров:{" "}
              {parsedDetails.totalMarkedProducts}
              {parsedDetails.totalProducts !==
                parsedDetails.totalMarkedProducts && (
                <Tooltip
                  id="marked-products-tooltip"
                  message="У части товаров некорректные КИЗы. Бот их удалит, чтобы УПД был верным и его принял маркетплейс."
                />
              )}
            </div>

            {parsedDetails.totalMarkedProducts !==
              parsedDetails.totalUniqueMarkedProducts &&
              parsedDetails.totalUniqueMarkedProducts !== 0 && (
                <div>
                  Количество товаров c уникальными КИЗами:{" "}
                  {parsedDetails.totalUniqueMarkedProducts}
                  <Tooltip
                    id="unique-products-tooltip"
                    message="В файле присутствуют дубли КИЗов, бот их удалит перед отправкой."
                  />
                </div>
              )}

            {!parsedDetails.isMonopallet && (
              <div>
                Вашему ИНН принадлежит: {parsedDetails.totalOwn}
                <Tooltip
                  id="own-products-tooltip"
                  message={
                    parsedDetails.totalOwn ===
                    parsedDetails.totalUniqueMarkedProducts
                      ? "Отправлять нужно только КИЗы, которые принадлежат вашему ИНН. У вас всё хорошо!"
                      : "Отправлять нужно только КИЗы, которые принадлежат вашему ИНН. Возможно, остальные КИЗ ещё не приняты вами или уже переданы маркетплейсу - бот удалит чужие КИЗ перед отправкой."
                  }
                />
              </div>
            )}

            {!parsedDetails.isMonopallet && (
              <div>
                Из них "В обороте": {parsedDetails.totalIntroduced}
                <Tooltip
                  id="introduced-products-tooltip"
                  message="Передавать нужно только КИЗы в статусе 'В обороте'. Иначе будут ошибки на стороне Честного Знака"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
