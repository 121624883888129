import { useState } from "react";
import { DatePicker, ConfigProvider, Space } from "antd";
import type { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import locale from "antd/locale/ru_RU";
import "antd/dist/reset.css";

interface TProps {
  onChange: (date: Dayjs) => void;
  placeholder?: string;
}

export default function DatePickerComponent({ onChange, placeholder }: TProps) {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    onChange(date!);
  };

  return (
    <ConfigProvider locale={locale}>
      <DatePicker
        style={{ color: "rgba(0, 0, 0, 0.6)" }}
        size="large"
        format="DD.MM.YYYY"
        placeholder={placeholder ?? "Выберите дату"}
        onChange={handleDateChange}
        value={selectedDate}
      />
    </ConfigProvider>
  );
}
