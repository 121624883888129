import styles from "./AccessTokenStatistics.module.scss";
import { AccessTokenInfo } from "src/models/Models";

interface TProps {
  info: AccessTokenInfo;
}

export default function AccessTokenStatistics({ info: tokenInfo }: TProps) {
  return (
    <>
      <div className={styles.row}>
        <span className={styles.title}>Использовано:</span>{" "}
        {tokenInfo.usedCount}{" "}
        {!tokenInfo.isUnlimited && <span>из {tokenInfo.allowedUseCount}</span>}{" "}
        раз
      </div>{" "}
      <div className={styles.row}>
        <span className={styles.title}>Для:</span> {tokenInfo.usedInns.length}{" "}
        из {tokenInfo.allowedInnCount} ИНН{" "}
        {tokenInfo.usedInns.length > 0 && (
          <span>({tokenInfo.usedInns.join(", ")})</span>
        )}
      </div>
      {tokenInfo.daysLeft != null && (
        <div className={styles.row}>
          <span className={styles.title}>Осталось:</span> {tokenInfo.daysLeft}{" "}
          дней
        </div>
      )}
    </>
  );
}
