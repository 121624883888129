import { Link, useLocation } from "react-router-dom";
import styles from "./WithdrawSuccessSend.module.scss";
import { useNavigate } from "react-router-dom";

export default function WithdrawSuccessSend() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const totalProducts = queryParams.get("totalProducts");
  const totalUniqueProducts = queryParams.get("totalUniqueProducts");
  const totalOwnProducts = queryParams.get("totalOwnProducts");
  const withdrawn = queryParams.get("withdrawn");
  const returned = queryParams.get("returned");

  return (
    <div>
      <h2 className={styles.title}>✅ Статусы КИЗов успешно изменены</h2>
      <div className={styles.description}>
        <ul>
          <li>Количество строк в отчёте: {totalProducts}</li>
          <li>Обработано КИЗов: {totalUniqueProducts}</li>
          <li>Принадлежит вашему ИНН: {totalOwnProducts}</li>
          <li>Выведено из оборота: {withdrawn}</li>
          <li>Возвращено в оборот: {returned}</li>
        </ul>
      </div>
      <p className={styles.description}>
        Мы уже прислали в ваш телеграм отчёт о проделанной работе. Также можете
        проверить документы в ЧЗ по инструкции:
      </p>
      <div className={styles.description}>
        <ol>
          <li>
            Перейдите по этой{" "}
            <b>
              <Link
                target="_blank"
                to={`${process.env.REACT_APP_FAIR_SIGN_URL}dashboard`}
              >
                ссылке
              </Link>
            </b>{" "}
            и пройдите в нужную категорию товаров;
          </li>
          <li>Выберите "Документы" ➡️ "Исходящие";</li>
          <li>
            Там появились новые документы "Возврат в оборот" и/или "Вывод из
            оборота". <br /> Статусы должны измениться на{" "}
            <b>Обработан успешно</b>.
          </li>
        </ol>
      </div>
      <p className={styles.description}>
        Для обработки другого отчета вернитесь{" "}
        <b>
          <a href="#" onClick={() => navigate(-1)}>
            по этой ссылке
          </a>
        </b>
      </p>
    </div>
  );
}
