import { TextField } from "@mui/material";
import styles from "./AdditionalDetails.module.scss";
import { useEffect, useState } from "react";
import { AdditionalInfo } from "src/models/UpdModels";
import { MarketplaceType } from "src/models/Models";
import DatePickerComponent from "src/components/DatePicker/DatePickerComponent";

interface TProps {
  disabled?: boolean;
  onChange: (signer: AdditionalInfo | null) => void;
  isAddressMissing: boolean;
  isLegalEntity: boolean;
  marketplaceType: MarketplaceType;
}

export default function AdditionalDetails({
  disabled,
  onChange,
  isLegalEntity,
  isAddressMissing,
  marketplaceType,
}: TProps) {
  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfo>({
    firstName: "",
    lastName: "",
    middleName: "",
    jobTitle: "",
    address: "",
  });

  useEffect(() => {
    let allFieldsAreFilled = true;
    if (isAddressMissing) {
      allFieldsAreFilled =
        allFieldsAreFilled && additionalInfo.address!.length > 0;
    }

    if (isLegalEntity) {
      allFieldsAreFilled =
        allFieldsAreFilled &&
        additionalInfo.firstName.length > 0 &&
        additionalInfo.lastName.length > 0 &&
        additionalInfo.jobTitle.length > 0;
    }

    onChange(allFieldsAreFilled ? additionalInfo : null);
  }, [onChange, additionalInfo, isAddressMissing, isLegalEntity]);

  return (
    <>
      {isLegalEntity && (
        <div className={styles.two_columns_container}>
          <TextField
            label="Фамилия"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                lastName: e.target.value,
              })
            }
          />
          <TextField
            label="Имя"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                firstName: e.target.value,
              })
            }
          />
          <TextField
            label="Отчество"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                middleName: e.target.value,
              })
            }
          />
          <TextField
            label="Должность"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                jobTitle: e.target.value,
              })
            }
          />
        </div>
      )}
      {isAddressMissing && (
        <div className={styles.full_width_container}>
          <TextField
            label="Адрес юр. лица"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({ ...additionalInfo, address: e.target.value })
            }
          />
        </div>
      )}
      {marketplaceType === MarketplaceType.Lamoda && (
        <div className={styles.two_columns_container}>
          <TextField
            label="Номер договора"
            size="small"
            disabled={disabled}
            onChange={(e) =>
              setAdditionalInfo({
                ...additionalInfo,
                agreementNumber: e.target.value,
              })
            }
          />
          <DatePickerComponent
            onChange={(date) => {
              setAdditionalInfo({
                ...additionalInfo,
                agreementDate: date,
              });
            }}
            placeholder="Дата договора"
          ></DatePickerComponent>
        </div>
      )}
    </>
  );
}
