import axios from "axios";
import {
  AccessTokenInfo,
  FulfillmentType,
  MarketplaceType,
} from "../models/Models";
import {
  ActualizeKizDocument,
  ParseWithdrawFromCirculationFileResponse,
  PrepareWithdrawFromCirculationResponse,
} from "src/models/WithdrawFromCirculationModels";

const baseUrl = process.env.REACT_APP_API_URL;

export const getAccessTokenInfo = async (
  token: string
): Promise<AccessTokenInfo> => {
  var response = await axios.get(`withdraw/token/${token}`, {
    baseURL: baseUrl,
  });

  return response.data;
};

export const parseReport = async (
  file: File,
  trueApiToken: string,
  token: string,
  sellerInn: string,
  marketplaceType: MarketplaceType
): Promise<ParseWithdrawFromCirculationFileResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("sellerInn", sellerInn);
  formData.append("token", token);

  let path = "";
  switch (marketplaceType) {
    case MarketplaceType.Wildberries:
      path = "withdraw/wb/report";
      break;
    case MarketplaceType.Ozon:
      path = "withdraw/ozon/report";
      break;
    case MarketplaceType.YandexMarket:
      path = "withdraw/yandex/report";
      break;
  }

  const response = await axios.post(path, formData, {
    baseURL: baseUrl,
    headers: { TrueApiToken: trueApiToken },
  });

  return response.data;
};

export const prepareWithdrawFromCirculation = async (
  trueApiToken: string,
  file: File,
  token: string,
  marketplaceType: MarketplaceType,
  sellerInn: string
): Promise<PrepareWithdrawFromCirculationResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("token", token);
  formData.append("sellerInn", sellerInn);
  formData.append("marketplaceType", marketplaceType);

  const response = await axios.post("withdraw/prepare", formData, {
    baseURL: baseUrl,
    headers: { TrueApiToken: trueApiToken },
  });

  return response.data;
};

export const actualizeKizStates = async (
  trueApiToken: string,
  token: string,
  documents: ActualizeKizDocument[],
  sellerInn: string,
  marketplaceType: MarketplaceType,
  fulfillmentType: FulfillmentType
): Promise<string> => {
  let body = {
    documents: documents,
    token: token,
    sellerInn: sellerInn,
    marketplaceType: marketplaceType,
    fulfillmentType: fulfillmentType,
  };
  const response = await axios.post("withdraw/send", body, {
    baseURL: baseUrl,
    headers: { TrueApiToken: trueApiToken },
  });

  return response.data;
};
